<template>
  <v-layout style="height:100%" justify-center align-center>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-layout>
</template>

<script>
export default {
  name: 'BaseLoading',
};
</script>
