<template>
  <v-container
    fluid
    grid-list-lg
    fill-height
    id="home"
    class="py-2"
  >
    <v-layout column>
      <v-flex xs12>
        <v-card
          flat
          class="border-round"
        >
          <v-card-title class="pt-0 pb-0">
            <select-module></select-module>
            <v-tabs
              v-model='tab'
              background-color='transparent'
              color='primary'
              grow
            >
              <v-tab style="text-transform: none !important;"
                v-for='tab in tabs'
                :key='tab'
              >
                {{ $t(`fileManager.${tab}`) }}
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-tabs-items v-model='tab' v-if="currentModule">
            <v-tab-item
              v-for='tab in tabs'
              :key='tab'
            >
              <v-card-text v-if="tab == 'machine-wise-parts'" id='home-container'>
                <base-loading v-if='loading'></base-loading>
                <no-result v-if='noResult'></no-result>
                <record-list>
                  <template #default='{ record, title }'>
                    <record :record='record' :title='title'></record>
                  </template>
                </record-list>
              </v-card-text>
              <v-card-text v-if="tab == 'machines'" id='home-container'>
                <base-loading v-if='loading'></base-loading>
                <no-result v-if='noResult'></no-result>
                  <Stations />
              </v-card-text>
              <v-card-text v-if="tab == 'parts'" id='home-container'>
                <base-loading v-if='loading'></base-loading>
                <no-result v-if='noResult'></no-result>
                  <Parts />
              </v-card-text>
              <v-card-text
                v-if="tab == 'general'" id='home-container'
                class="pt-12"
                style="text-align: center;"
              >
                <v-row class="pt-12">
                  <v-col><file-upload className="pt-6 pb-6 pl-8 pr-8"/></v-col>
                  <v-col>
                    <v-btn
                      small
                      color="success"
                      class="text-none pt-6 pb-6 pl-8 pr-8"
                      :to="{
                        name: 'preview',
                        query: {
                          tab: selectedTab,
                          module: currentModule,
                        },
                      }"
                    >
                      <v-icon small left>
                        mdi-eye-outline
                      </v-icon>
                      Preview
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="tab == 'machines-sub-machines'" id='home-container'>
                <base-loading v-if='loading'></base-loading>
                <no-result v-if='noResult'></no-result>
                <StationsWiseSubStations />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SelectModule from '../components/input/SelectModule.vue';
import RecordList from '../components/RecordList.vue';
import Record from '../components/Record.vue';
import BaseLoading from '../components/base/loading.vue';
import FileUpload from '../components/input/FileUpload.vue';
import NoResult from '../components/base/NoResult.vue';
import Stations from '../components/Stations.vue';
import StationsWiseSubStations from '../components/StationsWiseSubStations.vue';
import Parts from '../components/Parts.vue';

export default {
  name: 'Home',
  components: {
    SelectModule,
    RecordList,
    Record,
    BaseLoading,
    NoResult,
    Stations,
    Parts,
    FileUpload,
    StationsWiseSubStations,
  },
  computed: {
    ...mapState('fileManager', ['loading', 'noResult', 'selectedTab', 'currentModule']),
  },
  data() {
    return {
      tab: 0,
      tabs: [
        'machine-wise-parts',
        'machines',
        'parts',
        'general',
        'machines-sub-machines',
      ],
    };
  },
  watch: {
    tab(val) {
      const selectedTab = this.tabs[val];
      this.setSelectedTab(selectedTab);
    },
  },
  mounted() {
    this.setSelectedTab(this.tabs[this.tab]);
  },
  methods: {
    ...mapMutations('fileManager', ['setSelectedTab']),
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
#home-container {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
