<template>
  <v-dialog v-model="uploadDialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-on="on"
        v-bind="attrs"
        color="primary"
        class="text-none"
        :class="className"
      >
        <v-icon small left>
          $upload
        </v-icon>
        Upload
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <form ref="fileform">
          <div  class="text-center">
            <v-icon class="icon-class">mdi-upload</v-icon>
          </div>
          <div  class="text-center">
            <span class="title">
              {{ $t('fileManager.headerMsg') }}
            </span>
            <br>
            <span class="subtitle-1">
              {{ $t('fileManager.bodyMsg') }}
            </span>
          </div>
          <div  class="text-center" >
            <label class="custom-file-upload primary" >
              SELECT FILE
              <input
                multiple
                id="files"
                ref="files"
                type="file"
                accept=".mp4,.pdf,.png"
                @change="handleFilesUpload()"
              />
            </label>
          </div>
          <div v-for="(file, key) in files" class="file-listing" :key="key">
              <!-- <v-flex>
                <img class="preview" v-bind:ref="'preview'+parseInt( key )"/>
              </v-flex> -->
            <v-row>
              <v-col cols="9">
                <span  class="text-name" v-text="file.name"></span>
              </v-col>
              <v-col style="text-align: right;">
                <v-btn
                  text
                  icon
                  small
                  color="red"
                  title="remove"
                  @click="removeFile(key)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
            <!-- <progress max="100" :value.prop="uploadPercentage"></progress> -->
        </form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="uploadDialog=false"
          text
          color="red"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="submitFiles()"
          :disabled="!files.length"
          class="success"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

const contentEnum = Object.freeze({
  image: 'image',
  audio: 'audio',
  video: 'video',
  application: 'file',
});

export default {
  name: 'FileUpload',
  props: {
    // fileData: {
    //   type: Object,
    //   required: true,
    // },
    machine: {
      type: String,
      default: undefined,
    },
    submachine: {
      type: String,
      default: undefined,
    },
    part: {
      type: String,
      default: undefined,
    },
    className: {
      type: String,
    },
  },
  computed: {
    ...mapState('fileManager', ['assetId']),
  },
  data() {
    return {
      files: [],
      uploadPercentage: 0,
      dragAndDropCapable: false,
      uploadDialog: false,
    };
  },
  // mounted() {
  //   /*
  //       Determine if drag and drop functionality is capable in the browser
  //     */
  //   this.dragAndDropCapable = this.determineDragAndDropCapable();

  //   /*
  //       If drag and drop capable, then we continue to bind events to our elements.
  //     */
  //   if (this.dragAndDropCapable) {
  //     /*
  //         Listen to all of the drag events and bind an event listener to each
  //         for the fileform.
  //       */
  //     ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
  // .forEach((evt) => {
  //       /*
  //           For each event add an event listener that prevents the default action
  //           (opening the file in the browser) and stop the propagation of the event (so
  //           no other elements open the file in the browser)
  //         */
  //       this.$refs.fileform.addEventListener(evt, (e) => {
  //         e.preventDefault();
  //         e.stopPropagation();
  //       }, false);
  //     });

  //     /*
  //         Add an event listener for drop to the form
  //       */
  //     this.$refs.fileform.addEventListener('drop', (e) => {
  //       /*
  //           Capture the files from the drop event and add them to our local files
  //           array.
  //         */
  //       for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
  //         if (e.dataTransfer.files[i].type.includes('pdf')
  //             || e.dataTransfer.files[i].type.includes('png')
  //             || e.dataTransfer.files[i].type.includes('mp4')) {
  //           this.files.push(e.dataTransfer.files[i]);
  //           this.getImagePreviews();
  //         }
  //       }
  //     });
  //   }
  // },

  methods: {
    determineDragAndDropCapable() {
      /*
          Create a test element to see if certain events
          are present that let us do drag and drop.
        */
      const div = document.createElement('div');

      /*
          Check to see if the `draggable` event is in the element
          or the `ondragstart` and `ondrop` events are in the element. If
          they are, then we have what we need for dragging and dropping files.

          We also check to see if the window has `FormData` and `FileReader` objects
          present so we can do our AJAX uploading
        */
      return (('draggable' in div)
                || ('ondragstart' in div && 'ondrop' in div))
                && 'FormData' in window
                && 'FileReader' in window;
    },

    /*
        Gets the image preview for the file.
      */
    getImagePreviews() {
      /*
          Iterate over all of the files and generate an image preview for each one.
        */
      for (let i = 0; i < this.files.length; i += 1) {
        /*
            Ensure the file is an image file
          */
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
          /*
              Create a new FileReader object
            */
          const reader = new FileReader();

          /*
              Add an event listener for when the file has been loaded
              to update the src on the file preview.
            */
          reader.addEventListener('load', () => {
            this.$refs[`preview${parseInt(i, 10)}`][0].src = reader.result;
          }, false);

          /*
              Read the data for the file in through the reader. When it has
              been loaded, we listen to the event propagated and set the image
              src to what was loaded from the reader.
            */
          reader.readAsDataURL(this.files[i]);
        }
        // else {
        /*
              We do the next tick so the reference is bound and we can access it.
            */
        //   this.$nextTick(function () {
        //     this.$refs[`preview${parseInt(i, 10)}`][0].src = '/images/file.png';
        //   });
        // }
      }
    },

    ...mapActions('fileManager', ['postFileData', 'postElementData']),
    ...mapMutations('helper', ['setAlert']),
    addFiles() {
      this.$refs.files.click();
    },
    async submitFiles() {
      const elementData = {
        machinename: this.machine,
        submachine: this.submachine,
        partname: this.part,
      };
      delete elementData._id;
      delete elementData.questions;
      this.files.map(async (file) => {
        let filetype = null;
        const param = file.name.split('.');
        const formData = new FormData();
        const temp = file.type.split('/');
        const content = contentEnum[temp[0]] || 'file';
        formData.append(`files_${param}`, file);
        if (file.type.includes('pdf')) {
          filetype = 'file';
        } else if (file.type.includes('png')) {
          filetype = 'image';
        } else if (file.type.includes('mp4')) {
          filetype = 'video';
        }
        const filePayload = {
          formData,
          filename: param[0],
          fileextension: param[param.length - 1].toLowerCase(),
          // itemData: elementData,
          filetype,
          assetid: this.assetId,
          filecontent: content,
        };
        await this.postFileData({ filePayload, elementData });
      });
      this.files = [];
      this.uploadDialog = false;
    },
    handleFilesUpload() {
      let validFilename = null;
      const uploadedFiles = this.$refs.files.files;
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        validFilename = /[a-z0-9]/i.test(uploadedFiles[i].name); // /^[\w,\s-]+\.[A-Za-z0-9]{3}$/i.test(uploadedFiles[i].name);
        if (validFilename) {
          if (uploadedFiles[i].size < 7340032) {
            this.files.push(uploadedFiles[i]);
          } else {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'INVALID_SIZE',
            });
          }
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'INVALID_TEXT',
          });
        }
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
  },
};

</script>

<style>
 form{
    display: block;
    width: 100%;
    background: rgb(255, 255, 255);
    overflow: hidden;
  }

  progress{
    width: 400px;
    margin: auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .responsive-img {
    height: calc(100vh - 300px);
  }
 .header{
    /* color: black; */
    font-size: 24px;
    padding-left:5px;
    text-align: center;
 }
 .mdi-upload:before{
   font-size: 74px;
    /* color: black; */
 }
 input[type="file"] {
    display: none;
}
.custom-file-upload {
    /* background:white; */
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    margin-left: 5px;
    margin-bottom: 5%;
    border-radius: 4px;
    /* letter-spacing: 0.0892857143em */
}
.text-name{
    /* color: black; */
    font-size: 14px;
    padding-left: 10px;
}
.submit-btn{
    float: right;
}
</style>
