var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',_vm._l((_vm.machinewiseSubmachines),function(rec,key){return _c('v-expansion-panel',{key:key,staticClass:"elevation-0"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(key)+" ")]),_c('v-expansion-panel-content',_vm._l((rec),function(item,i){return _c('v-row',{key:`${item.machinename}-${i}`},[_c('v-col',{staticClass:"d-flex justify-space-between align-center mb-0",style:(i + 1 !== rec.length ? 'border-bottom: 1px solid #ccc;' : ''),attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{
              name: 'preview',
              query: {
                submachine: item.submachine,
                machinename: item.machinename,
                tab: _vm.selectedTab,
                module: _vm.currentModule,
              },
            }}},[_c('span',{domProps:{"textContent":_vm._s(item.submachine)}})]),_c('file-upload',{attrs:{"machine":item.machinename,"submachine":item.submachine}})],1)],1)}),1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }