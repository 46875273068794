var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-2",attrs:{"fluid":"","grid-list-lg":"","fill-height":"","id":"home"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"border-round",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('select-module'),_c('v-tabs',{attrs:{"background-color":"transparent","color":"primary","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab,staticStyle:{"text-transform":"none !important"}},[_vm._v(" "+_vm._s(_vm.$t(`fileManager.${tab}`))+" ")])}),1)],1),(_vm.currentModule)?_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab},[(tab == 'machine-wise-parts')?_c('v-card-text',{attrs:{"id":"home-container"}},[(_vm.loading)?_c('base-loading'):_vm._e(),(_vm.noResult)?_c('no-result'):_vm._e(),_c('record-list',{scopedSlots:_vm._u([{key:"default",fn:function({ record, title }){return [_c('record',{attrs:{"record":record,"title":title}})]}}],null,true)})],1):_vm._e(),(tab == 'machines')?_c('v-card-text',{attrs:{"id":"home-container"}},[(_vm.loading)?_c('base-loading'):_vm._e(),(_vm.noResult)?_c('no-result'):_vm._e(),_c('Stations')],1):_vm._e(),(tab == 'parts')?_c('v-card-text',{attrs:{"id":"home-container"}},[(_vm.loading)?_c('base-loading'):_vm._e(),(_vm.noResult)?_c('no-result'):_vm._e(),_c('Parts')],1):_vm._e(),(tab == 'general')?_c('v-card-text',{staticClass:"pt-12",staticStyle:{"text-align":"center"},attrs:{"id":"home-container"}},[_c('v-row',{staticClass:"pt-12"},[_c('v-col',[_c('file-upload',{attrs:{"className":"pt-6 pb-6 pl-8 pr-8"}})],1),_c('v-col',[_c('v-btn',{staticClass:"text-none pt-6 pb-6 pl-8 pr-8",attrs:{"small":"","color":"success","to":{
                      name: 'preview',
                      query: {
                        tab: _vm.selectedTab,
                        module: _vm.currentModule,
                      },
                    }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-eye-outline ")]),_vm._v(" Preview ")],1)],1)],1)],1):_vm._e(),(tab == 'machines-sub-machines')?_c('v-card-text',{attrs:{"id":"home-container"}},[(_vm.loading)?_c('base-loading'):_vm._e(),(_vm.noResult)?_c('no-result'):_vm._e(),_c('StationsWiseSubStations')],1):_vm._e()],1)}),1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }