var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0"},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.machineList),function(machine,index){return _c('v-col',{key:index,staticClass:"pa-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                name: 'preview',
                query: {
                  machinename: machine.machinename,
                  tab: _vm.selectedTab,
                  module: _vm.currentModule,
                },
            }}},[_vm._v(" "+_vm._s(machine.machinename)+" ")])],1)],1),_c('v-list-item-action',[_c('file-upload',{attrs:{"machine":machine.machinename}})],1)],1),_c('v-divider')],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }