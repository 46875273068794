<template>
  <v-card class="ma-0">
    <v-list class="pa-0">
      <v-col
        class="pa-0"
        v-for='(part, index) in parts'
        :key='index'
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                :to="{
                  name: 'preview',
                  query: {
                    partname: part.partname,
                    tab: selectedTab,
                    module: currentModule,
                  },
                }">
                {{ part.partname }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <file-upload :part='part.partname' />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-col>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import FileUpload from './input/FileUpload.vue';

export default {
  name: 'Parts',
  components: {
    FileUpload,
  },
  computed: {
    ...mapState('fileManager', ['parts', 'selectedTab', 'currentModule']),
  },
  data() {
    return {};
  },
};
</script>
