<template>
  <span>
    <v-row v-for="(item, i) in list" :key="`${item.machinename}-${i}`">
      <v-col
        cols="12"
        class="d-flex justify-space-between align-center mb-0"
        :style="i + 1 !== list.length ? 'border-bottom: 1px solid #ccc;' : ''"
      >
        <router-link
          :to="{
            name: 'preview',
            query: {
              partname: item.partname,
              machinename: item.machinename,
              tab: selectedTab,
              module: currentModule,
            },
          }">
          <span v-text="item.partname" />
        </router-link>
        <file-upload :machine="item.machinename" :part="item.partname" />
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import FileUpload from './input/FileUpload.vue';

export default {
  name: 'Record',
  components: {
    FileUpload,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('fileManager', ['currentModule', 'selectedTab']),
  },
};
</script>
