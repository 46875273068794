<template>
  <v-expansion-panels>
    <v-expansion-panel
      class="elevation-0"
      v-for="(rec, key) in machinewiseParts"
      :key="key"
    >
      <v-expansion-panel-header>
        {{ key }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <record :list="rec" :title="key"></record>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';
import Record from '../components/Record.vue';

export default {
  name: 'RecordList',
  components: {
    Record,
  },
  computed: {
    ...mapGetters('fileManager', ['masterList', 'machinewiseParts']),
  },
};
</script>
