<template>
  <v-select
    outline
    :items="modules"
    item-text="module"
    item-value="module"
    v-model="selectedModule"
    :label="$t('fileManager.labels.selectModule')"
  ></v-select>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SelectModule',
  computed: {
    ...mapState('fileManager', ['currentModule', 'modules']),
    selectedModule: {
      get() {
        return this.currentModule;
      },
      async set(val) {
        this.setCurrentModule(val);
        this.setNoResult(null);
        this.setLoading(true);
        // this.setMaster([]);
        // await this.getMaster();
        this.setLoading(null);
      },
    },
  },
  methods: {
    ...mapMutations('fileManager', ['setCurrentModule', 'setLoading', 'setNoResult']),
  },
};
</script>
