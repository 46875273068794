<template>
  <v-card class="ma-0">
    <v-list class="pa-0">
      <v-col
        class="pa-0"
        v-for='(machine, index) in machineList'
        :key='index'
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                :to="{
                  name: 'preview',
                  query: {
                    machinename: machine.machinename,
                    tab: selectedTab,
                    module: currentModule,
                  },
              }">
                {{ machine.machinename }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <file-upload :machine='machine.machinename' />
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-col>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FileUpload from './input/FileUpload.vue';

export default {
  name: 'MachineWiseParts',
  components: {
    FileUpload,
  },
  computed: {
    ...mapGetters('fileManager', ['machineList']),
    ...mapState('fileManager', ['selectedTab', 'currentModule']),
  },
  data() {
    return {};
  },
};
</script>
