var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',_vm._l((_vm.list),function(item,i){return _c('v-row',{key:`${item.machinename}-${i}`},[_c('v-col',{staticClass:"d-flex justify-space-between align-center mb-0",style:(i + 1 !== _vm.list.length ? 'border-bottom: 1px solid #ccc;' : ''),attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{
          name: 'preview',
          query: {
            partname: item.partname,
            machinename: item.machinename,
            tab: _vm.selectedTab,
            module: _vm.currentModule,
          },
        }}},[_c('span',{domProps:{"textContent":_vm._s(item.partname)}})]),_c('file-upload',{attrs:{"machine":item.machinename,"part":item.partname}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }