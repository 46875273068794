<template>
  <v-expansion-panels>
    <v-expansion-panel
      class="elevation-0"
      v-for="(rec, key) in machinewiseSubmachines"
      :key="key"
    >
      <v-expansion-panel-header>
        {{ key }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row v-for="(item, i) in rec" :key="`${item.machinename}-${i}`">
          <v-col
            cols="12"
            class="d-flex justify-space-between align-center mb-0"
            :style="i + 1 !== rec.length ? 'border-bottom: 1px solid #ccc;' : ''"
          >
            <router-link
              :to="{
                name: 'preview',
                query: {
                  submachine: item.submachine,
                  machinename: item.machinename,
                  tab: selectedTab,
                  module: currentModule,
                },
              }">
              <span v-text="item.submachine" />
            </router-link>
            <file-upload :machine="item.machinename" :submachine="item.submachine" />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FileUpload from './input/FileUpload.vue';

export default {
  name: 'MachineWiseSubMachines',
  components: {
    FileUpload,
  },
  computed: {
    ...mapGetters('fileManager', ['machineList', 'machinewiseSubmachines']),
    ...mapState('fileManager', ['selectedTab', 'currentModule']),
  },
  data() {
    return {};
  },
};
</script>
